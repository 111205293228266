.viewingfor {
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 8px;

    display: grid;
    grid-template-columns: 0.1fr 1fr;
}

.app-staff-header-content {
    font-size: 13px;
}

.app-staff-header {
    background-color: #fff2cd;
    color: black;
    width: 165px;
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    column-gap: 16px;
    row-gap: 16px;
    margin-bottom: 10px;
}

.app-content-page-cus {
    display: grid;
    grid-template-columns: 15% 45% 40%;
}

.app-content-page-cus-left {
    border: 1px solid gray;
}

.app-content-page-cus-left-Search {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.app-content-page-cus-left-Search input {
    width: 85%;
    height: 25px;
    padding-left: 10px;
}

.app-content-page-cus-center {
    border: 1px solid gray;
}

.page-cus-center-box {
    margin-top: 10px;
    
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* flex-direction: row; */
    /* align-items: center; */
    /* justify-content: center; */
}

.center-item {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
    border: 1px solid gray;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 260px;
    /* height: 230px;
    ; */
    color: #4880a5;
    float: left;
}

.center-item-header {
    border-bottom: 1px solid gray;
    height: 40px;
}

.center-item-header h4 {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.center-item-header h5 {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}

.center-item-header-status {
    padding-top: 1px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.center-item-header-status .left {
    float: left;
    padding-left: 5px;
}

.center-item-header-status .left .txtheader {
    border: solid;
    padding-left: 10px;
    padding-bottom: 1px;
    font-size: 12px;
    border-radius: 5px;
}

.center-item-header-status .right {
    float: right;
    padding-right: 5px;

}

.center-item-header-status .right .txtheader {
    border: solid;
    padding-left: 10px;
    padding-bottom: 1px;
    font-size: 12px;
    border-radius: 5px;
}


.center-item-detail {
    padding-top: 3px;
    padding-left: 10px;
}

.center-item-detail-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.center-item-detail-bar .left {
    width: 65;
}

.center-item-detail-bar .right {
    width: 35%;
}


.app-content-page-cus-right {
    border: 1px solid gray;
}


/* @media screen and (max-width: 1366px) {
    .app-content-pagecustomer-box-item>div {
        flex: 0 0 22%;
        margin-right: 1px;
    }
} */

/* .app-content-page-cus-center .dropdown-month {
    float: right;
} */

.DropDownYear {
    float: right;
    padding-right: 5px;
}

.viewingfor-header{
    padding-top: 10px;
}