.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #007acc;
  /* background-color: #fff; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.circle-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  border: none;
}

.name,
.code {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  color: white;
}


.dropdown-year {
  margin-top: 8px;
  margin-left: 10px;
  width: 120px;
  height: 30px;
  border-radius: 8px;
  text-align: center;
}

.dropdown-option {
  text-align: center;
}

.app-section {
  padding-top: 50px;
}

.app-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.app-content {
  display: grid;
  grid-template-columns: 15% 85%;
}

.app-content-main {
  border: 1px solid gray;
}

.app-content-box {}

.app-content-box-item {
  display: flex;
  flex-wrap: wrap;
}

.app-content-box-item>div {
  flex: 0 0 18%;
  /* ให้แต่ละ div มีขนาดเท่ากัน และรองรับสูงสุด 4 ตัวในแต่ละแถว */
  margin-right: 15px;
  /* ให้เว้นระยะห่างระหว่าง div ด้วยการกำหนดขนาดของ margin */
}

/* .app-content-box-item>div {
  flex: 1 0 20%;
  margin-right: 20px;
} */

@media screen and (max-width: 1366px) {
  .app-content-box-item>div {
    flex: 0 0 22%;
    /* ให้แต่ละ div มีขนาดเท่ากัน และรองรับสูงสุด 4 ตัวในแต่ละแถว */
    margin-right: 18px;
    /* ให้เว้นระยะห่างระหว่าง div ด้วยการกำหนดขนาดของ margin */
  }
}

.app-content-main-Search {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.app-content-main-Search input {
  width: 90%;
  height: 25px;
  padding-left: 10px;
}